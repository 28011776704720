import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faHotel,
  faPlane,
  faBus,
  faSuitcaseRolling,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";

import banner from "assets/banner.jpg";
import aboutUsImage from "assets/aboutUsImage.jpg";
import feature1 from "assets/feature.png";
import feature2 from "assets/feature.png";
import feature3 from "assets/feature.png";

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  const testimonials = [
    {
      quote:
        "With fratehaw, our business has achieved new levels of efficiency and convenience. Highly recommended!",
      author: "Emily R., World Explorer",
    },
    {
      quote:
        "The flexibility and support from fratehaw are unparalleled. A true game changer for our operations.",
      author: "James P., Sky High Travels",
    },
    {
      quote:
        "fratehaw’s intuitive tools have drastically improved our workflow. Excellent service all around!",
      author: "Olivia M., Global Journeys",
    },
  ];

  const services = [
    {
      icon: faGlobe,
      title: "Global Travel Solutions",
      description:
        "Connect with global suppliers for flights, hotels, and more, all in one place.",
    },
    {
      icon: faHotel,
      title: "Hotel Booking System",
      description: "Access thousands of hotels at competitive rates, wherever you are.",
    },
    {
      icon: faPlane,
      title: "Flight Management",
      description:
        "Book flights with ease and manage itineraries in real-time, anytime.",
    },
  ];

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="Revolutionize Your Business with fratehaw"
          className="w-full h-[70vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-red-800 to-transparent bg-opacity-70 flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">
              Let fratehaw Power Your Business
            </h1>
            <p className="text-lg text-gray-200 mb-8">
              Unrivaled corporate travel solutions. Anytime, anywhere, anyhow.
            </p>
            <button
              onClick={handleLearnMoreClick}
              className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700"
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <section className="py-12 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Who We Are
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <img
            src={aboutUsImage}
            alt="About fratehaw"
            className="w-full md:w-1/2 rounded-lg shadow-md"
          />
          <div className="md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700">
              At fratehaw, we specialize in cutting-edge solutions that streamline
              your travel operations and take your business to the next level.
            </p>
            <p className="mt-4 text-lg text-gray-700">
              Whether it’s booking flights, managing itineraries, or offering
              tailored solutions, we are your trusted partner in the B2B travel
              industry.
            </p>
          </div>
        </div>
      </section>

      {/* Key Benefits Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Why Choose fratehaw?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Seamless Integration
            </h3>
            <p className="text-gray-600">
              Easily integrate fratehaw with your existing systems for smooth
              operations.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              24/7 Support
            </h3>
            <p className="text-gray-600">
              Our dedicated support team is available anytime, anywhere to assist
              you.
            </p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Affordable Rates
            </h3>
            <p className="text-gray-600">
              Get competitive rates for all your travel needs, ensuring you stay
              within budget.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-12 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center"
            >
              <FontAwesomeIcon
                icon={service.icon}
                className="text-red-500 text-4xl mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* New Features Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Our Latest Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {[feature1, feature2, feature3].map((feature, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <img
                src={feature}
                alt={`Feature ${index + 1}`}
                className="w-full h-40 object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900">
                Feature {index + 1}
              </h3>
              <p className="text-gray-600">
                Enhance your workflow with this new feature.
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Expanded Testimonials Section */}
      <section className="py-12 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          What Our Clients Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
              <p className="font-medium text-red-700">
                {testimonial.author}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Ready to Transform Your Business?
        </h2>
        <p className="text-lg text-gray-700 mb-6">
          Discover how fratehaw can help you streamline your travel operations and
          grow your business.
        </p>
        <button
          onClick={handleLearnMoreClick}
          className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700"
        >
          Get Started
        </button>
      </section>
    </div>
  );
};

export default Home;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import NavbarItems from "components/navbar-items/NavbarItems";

const HamburgerMenu = ({
  isVisible,
  onHamburgerMenuToggle,
  isAuthenticated,
}) => {
  return (
    <div
      data-testid="hamburger-menu"
      className={`bg-black shadow-lg z-20 ${
        isVisible ? "fixed right-0 w-2/3 top-0 h-screen" : "hidden"
      } text-white`}
    >
      {/* Кнопка закрытия меню */}
      <div className="absolute right-5 top-5">
        <FontAwesomeIcon
          data-testid="menu-close__button"
          icon={faXmark}
          size="2x"
          color="#FFD700"
          onClick={onHamburgerMenuToggle}
        />
      </div>

      {/* Список ссылок */}
      <ul className="list-none mt-20 space-y-6 text-center text-xl">
        <NavbarItems
          onHamburgerMenuToggle={onHamburgerMenuToggle}
          isAuthenticated={isAuthenticated}
        />
      </ul>
    </div>
  );
};

export default HamburgerMenu;

import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "components/hamburger-menu/HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "contexts/AuthContext";
import NavbarItems from "components/navbar-items/NavbarItems";

const GlobalNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const onHamburgerMenuToggle = () => setIsVisible(!isVisible);

  return (
    <nav className="flex items-center justify-between px-8 py-4 bg-gradient-to-r from-red-800 to-red-600 text-white shadow-lg">
      {/* Логотип */}
      <Link to="/" className="text-2xl font-extrabold text-white">
        fratehaw
      </Link>

      {/* Основные ссылки для больших экранов */}
      <ul className="hidden md:flex space-x-8">
        <NavbarItems isAuthenticated={isAuthenticated} />
      </ul>

      {/* Иконка для мобильного меню */}
      <FontAwesomeIcon
        icon={faBars}
        className="text-white text-3xl cursor-pointer md:hidden"
        onClick={onHamburgerMenuToggle}
      />

      {/* Мобильное меню */}
      <HamburgerMenu
        isVisible={isVisible}
        onHamburgerMenuToggle={onHamburgerMenuToggle}
        isAuthenticated={isAuthenticated}
      />
    </nav>
  );
};

export default GlobalNavbar;

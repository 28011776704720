import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";

import contactImage from "assets/contactImage.webp";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={contactImage}
          alt="Contact fratehaw"
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-red-800 to-transparent bg-opacity-70 flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">Contact Us</h1>
            <p className="text-lg text-gray-200">
              Get in touch with us to learn more about how fratehaw can help your
              travel business thrive.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Info Section */}
      <section className="py-12 text-center bg-gray-50">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Get In Touch
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Email</h3>
            <p className="text-gray-600">contact@fratehaw.com</p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faPhone}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Phone</h3>
            <p className="text-gray-600">+1 (332) 885540005</p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Address</h3>
            <p className="text-gray-600">
              21445 Central Ave, Suite 567, San-Antonio, USA
            </p>
          </div>
          <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faBusinessTime}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Business Hours
            </h3>
            <p className="text-gray-600">Mon - Fri: 9am - 6pm</p>
            <p className="text-gray-600">Sat - Sun: Closed</p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Send Us a Message
        </h2>
        <div className="max-w-4xl mx-auto bg-white p-8 shadow-md rounded-lg">
          {submitted ? (
            <div className="text-center">
              <h3 className="text-2xl text-green-600 mb-4">
                Thank you for reaching out!
              </h3>
              <p className="text-lg text-gray-600">
                We have received your message and will get back to you shortly.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="flex flex-col md:flex-row gap-6">
                <div className="flex-1">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    className="w-full px-6 py-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    required
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    className="w-full px-6 py-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    required
                  />
                </div>
              </div>
              <div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows="6"
                  className="w-full px-6 py-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                  required
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700"
                >
                  Send Message
                </button>
              </div>
            </form>
          )}
        </div>
      </section>

      {/* Map Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Find Us on the Map
        </h2>
        <div className="relative w-full h-[400px] mx-auto rounded-lg shadow-md">
          {/* Встраиваем карту через iframe */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.0603843785216!2d-74.00601548459364!3d40.71277577933011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a1d7a8032b1%3A0x2be604b52022595b!2sStatue%20of%20Liberty!5e0!3m2!1sen!2sus!4v1635189875928!5m2!1sen!2sus"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />
        </div>
      </section>
    </div>
  );
};

export default Contact;

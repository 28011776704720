import { useState } from "react";
import { Link } from "react-router-dom";
import Toast from "components/ux/toast/Toast";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const [loginData, setLoginData] = useState({ email: "" });
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleForgotSubmit = (e) => {
    e.preventDefault();
    if (loginData.email) setSuccess(true);
    else setErrorMessage("Invalid email");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-dark-red-800 via-dark-red-900 to-black text-white">
      <div className="container max-w-md p-6 shadow-lg bg-gray-800 rounded-lg">
        {success ? (
          <div className="text-center">
            <h3 className="text-2xl font-semibold text-green-400 mb-4">
              Email Sent!
            </h3>
            <p>Please check your inbox.</p>
            <Link
              to="/"
              className="inline-block mt-4 px-6 py-2 bg-blue-300 rounded-lg hover:bg-blue-400"
            >
              Go to Home
            </Link>
          </div>
        ) : (
          <form onSubmit={handleForgotSubmit}>
            <h2 className="text-2xl font-semibold text-blue-300 mb-4">
              Reset Password
            </h2>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={loginData.email}
              onChange={handleInputChange}
              className="w-full mb-4 bg-gray-700 border rounded-lg py-2 px-4 focus:outline-none"
            />
            {errorMessage && (
              <p className="text-red-500 mb-2">{errorMessage}</p>
            )}
            <button
              type="submit"
              className="w-full bg-blue-300 text-black py-2 rounded-lg hover:bg-blue-400"
            >
              Send Reset Link
            </button>
            <Link to="/login" className="block text-blue-300 text-center mt-4">
              Back to login
            </Link>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

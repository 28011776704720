import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/home/Home";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./routes/login/Login";
import Register from "./routes/register/Register";
import AboutUs from "./routes/about-us/AboutUs";
import BaseLayout from "./routes/layouts/base-layout/BaseLayout";
import ForgotPassword from "./routes/forgot-password/ForgotPassword";
import Contact from "routes/contact/Contact";
import Pricing from "routes/Pricing/Pricing";
import Career from "routes/career/Career";

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/travel-management",
        element: <Pricing />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      // Добавляем обработку неизвестных маршрутов
      {
        path: "*",
        element: <Home />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

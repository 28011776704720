import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faHotel,
  faShieldAlt,
  faCogs,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import hotelImage from "assets/hotelImage.jpg";
import riskManagementImage from "assets/riskManagementImage.webp";
import innovationImage from "assets/innovationImage.jpg";
import uniqueSolutionsImage from "assets/uniqueSolutionsImage.avif";

const Solutions = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src="assets/solutions-hero.jpg"
          alt="Our Solutions"
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-red-800 to-transparent bg-opacity-70 flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">
              Our Solutions & Services
            </h1>
            <p className="text-lg text-gray-200">
              Offering the most comprehensive solutions to streamline your
              business travel needs, tailored to your industry.
            </p>
          </div>
        </div>
      </div>

      {/* Business Travel Management Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Business Travel Management
        </h2>
        <div className="max-w-6xl mx-auto px-6">
          <p className="text-lg text-gray-600 mb-6">
            Managing corporate travel can be a complex task, but it doesn't have
            to be. At fratehaw, we simplify the process, offering a range of
            solutions that cater to the unique needs of your company. From
            employee travel services to cost-effective solutions, we ensure your
            team stays productive, safe, and engaged while traveling.
          </p>
          <p className="text-lg text-gray-600">
            Whether you're a small enterprise or a global corporation, fratehaw’s
            tools and services help you streamline your travel program and
            achieve seamless operations, all while optimizing costs and
            improving employee satisfaction.
          </p>
        </div>
      </section>

      {/* Unique Solutions Section */}
      <section className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Unique Travel Solutions
        </h2>
        <div className="max-w-6xl mx-auto px-6 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="flex justify-center">
            <img
              src={uniqueSolutionsImage}
              alt="Unique Solutions"
              className="w-full h-[400px] object-cover rounded-lg shadow-md"
            />
          </div>
          <div className="self-center">
            <p className="text-lg text-gray-600 mb-6">
              Not all industries have the same needs. For industries like
              energy, life sciences, media, or entertainment, you need
              specialized travel services that are both flexible and secure.
              fratehaw offers tailored solutions designed to meet the complex
              demands of these sectors, ensuring that your workforce is
              well-supported no matter the challenge.
            </p>
            <p className="text-lg text-gray-600">
              With a dedicated team that understands the unique requirements of
              each industry, we provide the expertise to handle everything from
              remote locations to high-security events.
            </p>
          </div>
        </div>
      </section>

      {/* Keep Employees Moving Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Keep Your Employees Moving
        </h2>
        <div className="max-w-6xl mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="self-center">
            <p className="text-lg text-gray-600 mb-6">
              In today’s fast-paced business world, travel disruptions are
              inevitable. However, keeping your employees productive and on
              track is essential. fratehaw provides the latest digital tools and
              services that allow your team to stay connected and productive
              even when traveling.
            </p>
            <p className="text-lg text-gray-600">
              Our booking tools, real-time updates, and efficient support keep
              your employees moving smoothly through their travel experience,
              ensuring minimal disruptions and maximum efficiency.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={hotelImage}
              alt="Hotel Services Rate Hawk"
              className="w-full h-[400px] object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </section>

      {/* Hotel Offering Section */}
      <section className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Hotel Offerings
        </h2>
        <div className="max-w-6xl mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="self-center">
            <p className="text-lg text-gray-600 mb-6">
              When your employees need to stay overnight during business trips,
              we ensure they get the best quality hotel rooms at competitive
              prices. fratehaw's hotel offerings, powered by RoomIt, provide a
              range of options to suit your company's needs, offering both
              comfort and affordability.
            </p>
            <p className="text-lg text-gray-600">
              Whether it’s a quick overnight stay or an extended visit, we
              guarantee safe, high-quality accommodations at the best available
              rates, no matter how your team books.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={hotelImage}
              alt="Hotel Services Rate Hawk"
              className="w-full h-[400px] object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </section>

      {/* Risk Management Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Managing Risk
        </h2>
        <div className="max-w-6xl mx-auto px-6 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="flex justify-center">
            <img
              src={riskManagementImage}
              alt="Risk Management"
              className="w-full h-[400px] object-cover rounded-lg shadow-md"
            />
          </div>
          <div className="self-center">
            <p className="text-lg text-gray-600 mb-6">
              We help you manage both the physical and data-related risks that
              come with business travel. fratehaw ensures your employees’ safety
              by predicting, managing, and mitigating potential risks, from
              travel disruptions to safety concerns.
            </p>
            <p className="text-lg text-gray-600">
              Our services provide comprehensive safety plans, real-time alerts,
              and secure communication channels, so you can always stay one step
              ahead in keeping your team safe.
            </p>
          </div>
        </div>
      </section>

      {/* Innovation & Insights Section */}
      <section className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Driving Innovation & Insights
        </h2>
        <div className="max-w-6xl mx-auto px-6 grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="self-center">
            <p className="text-lg text-gray-600 mb-6">
              At fratehaw, we don’t just follow industry trends – we set them. Our
              team is dedicated to tracking market shifts and implementing the
              latest technologies to improve travel management. Our insights
              help you stay ahead of the curve, making strategic decisions with
              data-driven intelligence.
            </p>
            <p className="text-lg text-gray-600">
              From advanced analytics to innovative solutions, we provide the
              tools that drive smarter decisions for your business travel
              operations.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={innovationImage}
              alt="Innovation"
              className="w-full h-[400px] object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-red-700 text-center text-white">
        <h2 className="text-3xl font-semibold mb-6">
          Ready to Optimize Your Business Travel?
        </h2>
        <p className="text-lg mb-6">
          Let us show you how fratehaw can enhance your business travel
          management. Reach out today to discuss how we can create a tailored
          travel program for your company.
        </p>
        <a
          href="contact.html"
          className="bg-white text-red-700 font-semibold py-3 px-6 rounded-full text-lg hover:bg-gray-100"
        >
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default Solutions;

import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-gray-300 hover:text-white transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-gradient-to-r from-red-800 to-red-600 text-gray-300 py-8">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Информация о компании */}
          <div className="space-y-2">
            <h4 className="text-xl font-bold text-white">Company</h4>
            <FooterLink to="/about-us" label="About Us" />
            <FooterLink to="/contact" label="Contact" />
            <FooterLink to="/privacy-policy" label="Privacy Policy" />
          </div>

          {/* Технологии */}
          <div className="space-y-2">
            <h4 className="text-xl font-bold text-white">Technology</h4>
            <FooterLink to="/travel-management" label="Travel Management" />
          </div>

          {/* Подписка на новости */}
          <div className="space-y-2">
            <h4 className="text-xl font-bold text-white">Stay Updated</h4>
            <p className="text-sm text-gray-400">
              Join our newsletter for the latest updates and travel tips.
            </p>
            <form className="flex mt-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full p-2 rounded-l bg-gray-700 placeholder-gray-400 focus:outline-none"
              />
              <button className="px-4 py-2 bg-red-700 hover:bg-red-800 text-white font-semibold rounded-r transition duration-300">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="text-center mt-6 border-t border-gray-600 pt-4">
          <p className="text-gray-400">Designed by fratehaw</p>
          <p className="text-gray-400">
            &copy; {new Date().getFullYear()} fratehaw. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faGlobe,
  faUsers,
  faStar,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import teamPhoto1 from "assets/teamPhoto1.jpg";
import teamPhoto2 from "assets/teamPhoto2.jpg";
import founderPhoto from "assets/founderPhoto.jpg";

const About = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src="assets/about-hero.jpg"
          alt="About fratehaw"
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-red-800 to-transparent bg-opacity-70 flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">About Us</h1>
            <p className="text-lg text-gray-200">
              Discover the story behind fratehaw and how we help businesses
              succeed in the travel industry.
            </p>
          </div>
        </div>
      </div>

      {/* Company Story Section */}
      <section className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">Our Story</h2>
        <div className="max-w-3xl mx-auto px-6">
          <p className="text-lg text-gray-600 mb-6">
            Founded with a passion for travel and innovation, fratehaw has been a
            trusted partner for B2B travel businesses around the world. From its
            humble beginnings, the company has grown to offer cutting-edge
            solutions that empower businesses to expand their global reach. We
            bring together the best of technology, expertise, and customer
            service to create seamless travel experiences for our clients.
          </p>
          <p className="text-lg text-gray-600">
            At fratehaw, our goal is to simplify the complexities of the travel
            industry while providing our partners with unparalleled support.
            Whether you're a small agency or a large enterprise, we are here to
            help you succeed in a dynamic and ever-evolving market.
          </p>
        </div>
      </section>

      {/* Our Founder Section */}
      <section className="py-16">
        <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <h2 className="text-3xl font-semibold text-red-700 mb-6">
              Meet Our Founder
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              fratehaw was founded by [Founder's Name], a visionary leader with
              over [X] years of experience in the travel industry. With a deep
              understanding of both the challenges and opportunities that the
              travel sector presents, [Founder's Name] has been at the forefront
              of creating innovative solutions to help businesses thrive.
            </p>
            <p className="text-lg text-gray-600">
              [Founder's Name] believes that the future of travel lies in
              collaboration and technology, and that is why fratehaw was created:
              to provide businesses with the tools they need to streamline their
              operations, engage customers more effectively, and stay ahead of
              the competition.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={founderPhoto}
              alt="Founder"
              className="w-80 h-80 object-cover rounded-full border-4 border-red-600"
            />
          </div>
        </div>
      </section>

      {/* Our Team Section */}
      <section className="py-16 bg-gray-50">
        <h2 className="text-3xl font-semibold text-red-700 text-center mb-6">
          Meet The Team
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="text-center">
            <img
              src={teamPhoto1}
              alt="Team Member 1"
              className="w-60 h-60 object-cover rounded-full mx-auto mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900">John Doe</h3>
            <p className="text-gray-600">CEO & Founder</p>
          </div>
          <div className="text-center">
            <img
              src={teamPhoto2}
              alt="Team Member 2"
              className="w-60 h-60 object-cover rounded-full mx-auto mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900">Jane Smith</h3>
            <p className="text-gray-600">Head of Operations</p>
          </div>
          {/* Add more team members as needed */}
        </div>
      </section>

      {/* Our Services Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Our Services
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faGlobe}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Global Reach
            </h3>
            <p className="text-gray-600">
              Our platform connects travel businesses worldwide, providing
              access to a global network of partners and customers.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faHandshake}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              B2B Solutions
            </h3>
            <p className="text-gray-600">
              We offer tailored solutions designed to meet the unique needs of
              B2B travel businesses, from booking engines to customer management
              tools.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faStar}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Exceptional Support
            </h3>
            <p className="text-gray-600">
              Our team is always ready to support your business with expert
              advice, technical assistance, and customer service.
            </p>
          </div>
        </div>
      </section>

      {/* Our Advantages Section */}
      <section className="py-16 bg-gray-50 text-center">
        <h2 className="text-3xl font-semibold text-red-700 mb-6">
          Why Choose Us?
        </h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Global Presence
            </h3>
            <p className="text-gray-600">
              With clients in over 50 countries, fratehaw has a proven track
              record of delivering successful solutions for travel businesses
              globally.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faUsers}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Dedicated Team
            </h3>
            <p className="text-gray-600">
              Our dedicated team of experts ensures that your business always
              has the right tools and support to succeed.
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-md">
            <FontAwesomeIcon
              icon={faStar}
              className="text-red-500 text-4xl mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Proven Results
            </h3>
            <p className="text-gray-600">
              We deliver measurable results, from increased bookings to improved
              customer satisfaction and retention.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
